.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.hero {
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* //////////////////Form////////////////////////////////// */

.MuiFormControl-root .MuiInputBase-root {
  color: white;
  background: #1f172c;
}

.MuiInputBase-input {
  background: none;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  color: white;
}

.MuiInputBase-root .MuiOutlinedInput-notchedOutline {
  border-color: #6f263b;
}

.Mui-focused {
  color: white !important;
  border-color: white !important;
} 

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #6f263b !important;
}

.MuiFormControl-root .MuiInputBase-root.MuiOutlinedInput-multiline textarea {
  min-height: 100px;
}

/* //////////////////End Form////////////////////////////////// */

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
